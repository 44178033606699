<template>
  <section class="vh-100 gradient-custom">
    <loading :active="isLoading" :is-full-page="true"></loading>
    <div class="container py-4 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card" style="border-radius: 1rem">
            <div class="card-body p-5 text-center">
              <h2 class="fw-bold">Spontantællinger</h2>
              <span
                >Læs om spontantællinger
                <a
                  target="_blank"
                  href="https://www.dof.dk/fakta-om-fugle/punkttaellingsprogrammet"
                  >her</a
                ></span
              >
              <div class="mb-md-5 mt-md-4 pb-5">
                <div
                  class="home-large-logo-div d-flex justify-content-center mt-4"
                >
                  <div class="row">
                    <div class="col-6 offset-3">
                      <img class="img-fluid" src="@/assets/doflogo.png" />
                    </div>
                  </div>
                </div>
                <PWAInstallPromp />
                <div
                  v-if="showAlert"
                  class="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  Kombinationen af obserkode og adgangskode kunne ikke findes
                  <button
                    type="button"
                    class="btn-close"
                    @click="toggleShowAlert()"
                    aria-label="Close"
                  ></button>
                </div>

                <form>
                  <div class="form-outline form-white mb-2">
                    <input
                      v-model="username"
                      autocomplete="username"
                      type="text"
                      id="typeUsername"
                      placeholder="Obserkode"
                      class="form-control form-control-lg"
                    />
                    <label class="form-label" for="typeUsername"
                      >Brugernavn</label
                    >
                  </div>

                  <div class="form-outline form-white mb-2">
                    <input
                      v-model="password"
                      autocomplete="current-password"
                      placeholder="Kodeord"
                      type="password"
                      id="typePasswordX"
                      class="form-control form-control-lg"
                    />
                    <label class="form-label" for="typePasswordX"
                      >Kodeord</label
                    >
                  </div>

                  <p class="small mb-2 pb-lg-2">
                    <a
                      target="_blank"
                      class="text-50"
                      href="https://dofbasen.dk/reminder.php"
                      >Glemt kodeord?</a
                    >
                  </p>

                  <transition name="slide-fade">
                    <button
                      v-if="!isLoginCredentialsEmpty"
                      class="btn btn-primary px-5"
                      @click.prevent="attemptLogin"
                      type="submit"
                    >
                      Login
                    </button>
                  </transition>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ageRepository from "../idb/repositories/ageRepository";
import siteRepository from "../idb/repositories/siteRepository";
import speciesRepository from "../idb/repositories/specieRepository";
import plumageRepository from "../idb/repositories/plumageRepository";
import primaryBehaviourRepository from "../idb/repositories/primaryBehaviourRepository";
import secondaryBehaviourRepository from "../idb/repositories/secondaryBehaviourRepository";
import directionRepository from "../idb/repositories/directionRepository";
import atlasSquareRepository from "../idb/repositories/atlasSquareRepository";
import atlasSquarePointRepository from "../idb/repositories/atlasSquarePointRepository";
import generalAtlasSquareStatistics from "../idb/repositories/generalAtlasSquareStatisticRepository";
import speciesChecksumRepository from "../idb/repositories/speciesChecksumRepository";
import sitesChecksumRepository from "../idb/repositories/sitesChecksumRepository";
import specieRankingRepository from "../idb/repositories/SpecieRankingRepository";
import atlas32Squares from "@/assets/json/atlas_squares/atlas_squares_utm_32.json";
import atlas33Squares from "@/assets/json/atlas_squares/atlas_squares_utm_33.json";
import PWAInstallPromp from "@/components/PWAInstallPrompt";
import axios from "axios";
import synchronizationService from "../synchronization/synchronizationService";
import localforage from "localforage";
// Import component
import Loading from "vue3-loading-overlay";
// Import stylesheet
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
const alarmSound = require("../assets/audio/level-completed.mp3");
const offlineMapImage = require("../assets/offline-denmark.png");

export default {
  name: "Login",
  components: {
    PWAInstallPromp,
    localforage,
    Loading,
  },
  data() {
    return {
      username: null,
      password: null,
      showAlert: false,
      alarmSound,
      offlineMapImage,
      isLoading: false,
    };
  },
  mounted() {
    console.time("preparing data");
    // Get data necessary for app to work properly
    atlasSquareRepository.insertAtlasSquares(atlas32Squares);
    atlasSquareRepository.insertAtlasSquares(atlas33Squares);

    console.timeEnd("preparing data");
  },
  methods: {
    async getData() {
      console.log("getData()");
      await this.getPrimaryBehaviours().then((response) => {
        primaryBehaviourRepository.insertPrimaryBehaviours(response.data);
      });
      await this.getSecondaryBehaviours().then((response) => {
        secondaryBehaviourRepository.insertSecondaryBehaviours(response.data);
      });
      await this.getDirections().then((response) => {
        directionRepository.insertDirections(response.data);
      });
      await this.getAges().then((response) => {
        ageRepository.insertAges(response.data);
      });
      await this.getSitesChecksum().then((response) => {
        sitesChecksumRepository.insertSitesChecksum({
          checksum: response.data,
        });
      });
      await this.getSpeciesChecksum().then((response) => {
        speciesChecksumRepository.insertSpeciesChecksum({
          checksum: response.data,
        });
      });
      await this.getPlumages().then((response) => {
        plumageRepository.insertPlumages(response.data);
      });
      await this.getSpecies().then((response) => {
        speciesRepository.insertSpecies(response.data);
      });
      await this.getSites().then((response) => {
        siteRepository.insertSites(response.data);
      });
      await this.getAtlasSquarePoints().then((response) => {
        atlasSquarePointRepository.insertAtlasSquarePoints(response.data);
      });
      await this.getSpecieRankings().then((response) => {
        specieRankingRepository.insertSpecieRankings(response.data);
      });
      await this.getGeneralAtlasSquareStatistics().then((response) => {
        generalAtlasSquareStatistics.insertGeneralAtlasSquareStatistics(
          response.data
        );
      });
    },
    getAges() {
      return axios.get("/public/ages");
    },
    getPlumages() {
      return axios.get("/public/plumages");
    },
    getSpecies() {
      return axios.get("/public/species");
    },
    getSites() {
      return axios.get("/public/sites");
    },
    getSpeciesChecksum() {
      return axios.get("/public/species/checksum");
    },
    getSitesChecksum() {
      return axios.get("/public/sites/checksum");
    },
    getAtlasSquarePoints() {
      return axios.get("/spontan/atlas-squares/points");
    },
    getSpecieRankings() {
      return axios.get("/spontan/statistics/general/species");
    },
    getGeneralAtlasSquareStatistics() {
      return axios.get("/spontan/statistics/general");
    },
    getPrimaryBehaviours() {
      return axios.get("/public/behaviours/primary");
    },
    getSecondaryBehaviours() {
      return axios.get("/public/behaviours/secondary");
    },
    getDirections() {
      return axios.get("/public/behaviours/direction");
    },
    toggleShowAlert() {
      this.showAlert = !this.showAlert;
    },
    async attemptLogin() {
      let inputs = {
        username: this.username,
        password: this.password,
      };
      const response = await axios.post("login", inputs).catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          console.log(error.message);
          if (error.response.status) {
            // Display message for user indicating login credentials were not correct
            this.toggleShowAlert();
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          this.toggleShowAlert();
        }
      });
      if (
        response &&
        response.data.access_token &&
        response.data.refresh_token
      ) {
        localStorage.setItem(
          "access_token",
          JSON.stringify(response.data.access_token)
        );
        localStorage.setItem(
          "refresh_token",
          JSON.stringify(response.data.refresh_token)
        );
        console.log("Received tokens from DOFbasen, now logging in..");
        this.login();
      }
    },
    async prepareAudio() {
      fetch(this.alarmSound)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          localforage.setItem("audio", blob);
        });
    },
    async prepareOfflineMap() {
      fetch(this.offlineMapImage)
        .then((res) => res.blob())
        .then((blob) => {
          localforage.setItem("offlineMap", blob);
        });
    },
    async login() {
      console.log("logging in");
      this.isLoading = true;
      await this.getData();
      this.prepareAudio();
      this.prepareOfflineMap();
      synchronizationService.setSynchCookie();
      this.isLoading = false;
      this.$router.push("/");
    },
  },
  computed: {
    isLoginCredentialsEmpty() {
      if (
        this.username &&
        this.username.trim() &&
        this.password &&
        this.password.trim()
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.gradient-custom {
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(45deg, #1f7aaf, #51af30);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(45deg, #1f7aaf, #51af30);
}
</style>
